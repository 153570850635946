.login {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-gray);

  .login-container {
    padding: 10px;
    width: 100%;
    max-width: 450px;
    height: 400px;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgb(16 22 26 / 10%), 0 4px 8px rgb(16 22 26 / 20%),
      0 18px 46px 6px rgb(16 22 26 / 20%);
    margin: 20px;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 20vw;
      max-width: 100px;
    }
    .title {
      text-align: center;
      font-weight: 900;
      letter-spacing: 0.3rem;
      color: var(--theme-green);
      padding: 2%;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    .form-group {
      display: flex;
      flex-direction: column;

      input {
        background-color: var(--theme-gray);
        border: 0.005rem solid var(--theme-dark-gray);
        border-radius: 5px;
        text-align: center;
        width: 280px;
        max-width: 300px;
        padding-top: 3%;
        padding-bottom: 3%;
      }

      label {
        color: var(--theme-dark-gray);
      }
    }
  }

  .btn {
    border-radius: 1rem;
    background-image: none;
    background-color: var(--theme-dark-gray);
    margin: auto;
    color: white;
    width: 30vw;
    max-width: 140px;
    margin-top: 30px;
  }

  .another-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;

    .forgot-password {
      color: var(--theme-dark-gray);
    }
    .register {
      display: flex;
      align-items: center;
    }
  }
}
