.permit {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;

  .select {
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: var(--theme-gray);
    font-family: Poppins;
    width: 100%;
  }

  .upload-photo {
    input {
      display: none;
    }
    .label {
      font-size: 12px;
      color: var(--theme-font-upload);
    }
    .file-box {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: gray 1px solid;
      padding: 5px;

      .text {
        font-size: 12px;
        margin: 0;
        color: var(--theme-font-upload);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .btn {

      }
    }
  }

  textarea {
    width: 100%;
    height: 100px;
    resize: none;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: var(--theme-gray);
    font-family: Poppins;
    &::-webkit-input-placeholder {
      font-family: Poppins;
    }
  }

  .submit-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    background-image: none;
    background-color: var(--theme-green);
    border: none;
    color: white;
    width: 100%;
    padding: 20px;
    text-align: center;
  }
}
