.toast {
  transition: all .5s linear;  
  -webkit-transition: all .5s linear;  
  -moz-transition: all .5s linear;
  padding: 10px;
  border-radius: 10px;

  &.bg-danger {
    background-color: hsl(0, 100%, 70%);
  }

  &.bg-success {
    background-color: hsl(16, 100%, 70%);
  }
  
  .d-flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: white;
  }
}