.header {
  height: 60px;
  width: 100%;
  position: fixed;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: hsl(0, 0%, 40%) 0px -3px 10px;
  z-index: 999;

  .title {
    font-size: 18px;
    color: var(---theme-font-header);
  }

  .back-button {
    position: absolute;
    left: 20px;
  }

  .logout-button {
    position: absolute;
    right: 20px;
  }
}

.white-space {
  height: 60px;
}