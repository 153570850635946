.loading {
  width: 100%;
  height: 100vh;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 100;
}

.popup {
  width: 100%;
  height: 100vh;
  padding: 20px;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 9999;

  .alert-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      color: var(--theme-green);
    }
    .title {
      margin-top: 20px;
      text-align: center;
      font-size: 20px;
      font-weight: 800;
      color: var(--theme-font-popup-title);
    }
    .subtitle {
      text-align: center;
      font-size: 14px;
      color: var(--theme-font-popup-title);
    }
    .btn {
      background-color: var(--theme-green);
      border: none;
      border-radius: 5px;
      color: white;
      width: 100%;
      padding: 15px;
      margin-top: 80px;
      text-align: center;
    }
  }
}