.approval {
  width: 100%;
  position: relative;

  .tabs {
    position: fixed;
    width: 100%;
    padding-top: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      width: 49%;
      margin: auto;
      padding: 10px;
      text-align: center;
      border: none;
      border-bottom: 1px solid black;
      outline: none;
      background-color: #fff;

      &.active {
        background-color: hsl(0, 0%, 95%);
      }
    }
  }

  .list-view {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    padding-top: 55px;

    .card-leave {
      display: flex;
      width: 100%;
      padding: 3px;
      border: 0.5px hsl(0, 0%, 50%) solid;
      border-radius: 5px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &.Approved {
        border-color: hsl(120, 100%, 70%);
      }

      &.Rejected {
        border-color: hsl(0, 100%, 65%);
      }
      
      .icon-wrapper {
        padding: 0 10px;
        &.Approved {
          color: hsl(120, 100%, 40%);
        }
  
        &.Rejected {
          color: hsl(0, 100%, 60%);
        }
      }
    }
  }
}