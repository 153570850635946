.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 15px;

    img {
      width: 70px;
      height: 70px;
      background-color: var(--theme-dark-gray);
      border-radius: 100%;
      object-fit: cover;
    }

    .greeting {
      font-size: 20px;
      font-weight: 700;
      color: var(--theme-font-value);
    }

    .name {
      color: var(--theme-font-name);
      text-transform: capitalize;
    }
  }

  .button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    padding: 20px;

    .btn {
      border-radius: 3px;
      background-image: none;
      background-color: var(--theme-green);
      border: none;
      color: white;
      width: 100%;
      box-shadow: hsl(0, 0%, 40%) 0px 0px 3px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .text-section {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .title {
          font-size: 18px;
          font-weight: 700px;
        }

        .desc {
          font-size: 10px;
          font-weight: 300;
        }
      }
    }
  }
}
