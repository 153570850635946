@import url(//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css);
:root {
  --theme-green: #18b8ba;
  --theme-gray: #ebeef5;
  --theme-border: #e8e8e8;
  --theme-dark-gray: rgb(178 188 202);
  --theme-font-upload: #a7a7ad;
  --theme-font-header: #205072;
  --theme-font-legend: #464646;
  --theme-font-value: #222222;
  --theme-font-button: #505050;
  --theme-font-name: #747474;
  --theme-font-button-light: #fff;
  --theme-font-popup-title: #4a4a4a;
  --theme-font-popup-subtitle: #6b6b6e;
}

* {
  font-family: Poppins;
  margin: 0;
  box-sizing: border-box;
}

input, optgroup, select, textarea {
  font-size: 16px;
}

* :focus {
  outline: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading{width:100%;height:100vh;top:0;position:fixed;display:flex;justify-content:center;align-items:center;background-color:#fff;z-index:100}.popup{width:100%;height:100vh;padding:20px;top:0;position:fixed;display:flex;justify-content:center;align-items:center;background-color:#fff;z-index:9999}.popup .alert-container{display:flex;flex-direction:column;align-items:center}.popup .alert-container .icon{color:var(--theme-green)}.popup .alert-container .title{margin-top:20px;text-align:center;font-size:20px;font-weight:800;color:var(--theme-font-popup-title)}.popup .alert-container .subtitle{text-align:center;font-size:14px;color:var(--theme-font-popup-title)}.popup .alert-container .btn{background-color:var(--theme-green);border:none;border-radius:5px;color:#fff;width:100%;padding:15px;margin-top:80px;text-align:center}
.toast{transition:all .5s linear;-webkit-transition:all .5s linear;-moz-transition:all .5s linear;padding:10px;border-radius:10px}.toast.bg-danger{background-color:#f66}.toast.bg-success{background-color:#ff8f66}.toast .d-flex{display:flex;flex-direction:row;justify-content:center;align-items:center;grid-gap:3px;gap:3px;color:#fff}
.login{width:100%;height:100vh;display:flex;align-items:center;justify-content:center;background-color:var(--theme-gray)}.login .login-container{padding:10px;width:100%;max-width:450px;height:400px;border-radius:3px;box-shadow:0 0 0 1px rgba(16,22,26,.1),0 4px 8px rgba(16,22,26,.2),0 18px 46px 6px rgba(16,22,26,.2);margin:20px;background-color:#fff;display:flex;align-items:center;flex-direction:column;grid-gap:20px;gap:20px}.login .logo-container{display:flex;flex-direction:column;align-items:center}.login .logo-container img{width:20vw;max-width:100px}.login .logo-container .title{text-align:center;font-weight:900;letter-spacing:.3rem;color:var(--theme-green);padding:2%}.login .form-container{display:flex;flex-direction:column;align-items:center;grid-gap:10px;gap:10px;width:100%}.login .form-container .form-group{display:flex;flex-direction:column}.login .form-container .form-group input{background-color:var(--theme-gray);border:.005rem solid var(--theme-dark-gray);border-radius:5px;text-align:center;width:280px;max-width:300px;padding-top:3%;padding-bottom:3%}.login .form-container .form-group label{color:var(--theme-dark-gray)}.login .btn{border-radius:1rem;background-image:none;background-color:var(--theme-dark-gray);margin:auto;color:#fff;width:30vw;max-width:140px;margin-top:30px}.login .another-container{width:100%;display:flex;flex-direction:row;justify-content:space-between;padding-left:15px;padding-right:15px}.login .another-container .forgot-password{color:var(--theme-dark-gray)}.login .another-container .register{display:flex;align-items:center}
.approval{width:100%;position:relative}.approval .tabs{position:fixed;width:100%;padding-top:10px;background-color:#fff;display:flex;flex-direction:row;align-items:center}.approval .tabs button{width:49%;margin:auto;padding:10px;text-align:center;border:none;border-bottom:1px solid #000;outline:none;background-color:#fff}.approval .tabs button.active{background-color:#f2f2f2}.approval .list-view{display:flex;flex-direction:column;grid-gap:10px;gap:10px;padding:10px;padding-top:55px}.approval .list-view .card-leave{display:flex;width:100%;padding:3px;border:.5px gray solid;border-radius:5px;flex-direction:row;justify-content:space-between;align-items:center}.approval .list-view .card-leave.Approved{border-color:#6f6}.approval .list-view .card-leave.Rejected{border-color:#ff4d4d}.approval .list-view .card-leave .icon-wrapper{padding:0 10px}.approval .list-view .card-leave .icon-wrapper.Approved{color:#0c0}.approval .list-view .card-leave .icon-wrapper.Rejected{color:#f33}
.selfie-page{width:100%;height:90vh;position:relative;display:flex;flex-direction:column;align-items:center}.selfie-page .camera-container{width:100%;height:100%;object-fit:cover}.selfie-page .take-picture-btn{position:absolute;bottom:40px;background-image:none;background-color:var(--theme-green);border:none;border-radius:5px;box-shadow:none;color:#fff;width:90%;max-width:400px;padding:20px;text-align:center}.selfie-page .take-picture-btn span{display:flex;justify-content:center;align-items:center;grid-gap:10px;gap:10px}
.container{padding:10px 20px}.container .btn-selfie{background-image:none;background-color:var(--theme-green);border:none;border-radius:5px;color:#fff;width:100%;padding:20px;text-align:center}.container .btn-selfie span{display:flex;justify-content:center;align-items:center;grid-gap:10px;gap:10px}.image-capture{width:100%;aspect-ratio:4/3;background-color:#000;object-fit:contain;object-position:center}
.form-map{display:flex;flex-direction:column;padding:10px 20px;grid-gap:10px;gap:10px}.form-map label{font-size:14px;color:var(--theme-font-legend)}.form-map textarea{resize:none;padding:10px;border:none;border-radius:3px;background-color:var(--theme-gray);font-family:Poppins}.form-map textarea::-webkit-input-placeholder{font-family:FontAwesome,Poppins}.form-map .btn-icon{border-radius:4px;border:var(--theme-green) solid 1px;background-color:#fff;color:var(--theme-font-button);text-align:center;max-width:-webkit-max-content;max-width:max-content;padding:5px;display:flex;align-items:center;flex-direction:row;grid-gap:5px;gap:5px}
.clock-in{width:100%;position:relative}.clock-in .container{padding:10px 20px}.clock-in .reason{width:100%;height:100px;resize:none;padding:10px;border:none;border-radius:3px;background-color:var(--theme-gray);font-family:Poppins;margin-bottom:50px}.clock-in .reason::-webkit-input-placeholder{font-family:Poppins}.clock-in .submit-btn{position:fixed;bottom:0;background-image:none;background-color:var(--theme-green);border:none;color:#fff;width:100%;padding:20px;text-align:center}
.clock-out{width:100%;position:relative}.clock-out .container{padding:10px 20px}.clock-out .work-duration{padding:10px 20px;display:flex;flex-direction:column;grid-gap:10px;gap:10px;margin-bottom:50px}.clock-out .work-duration .title{color:var(--theme-font-legend);font-size:14px}.clock-out .work-duration .radio{display:none}.clock-out .work-duration .radio:checked~.card-work-duration{background-color:var(--theme-gray)}.clock-out .work-duration .card-work-duration{display:flex;flex-direction:column;grid-gap:10px;gap:10px;background-color:#fff;border:var(--theme-border) 1px solid;border-radius:4px;padding:20px}.clock-out .work-duration .card-work-duration .title{display:flex;justify-content:space-between;align-items:center;color:#4e4e4e;font-size:15px;font-weight:800}.clock-out .work-duration .card-work-duration .duration{display:flex;justify-content:space-between;align-items:center;color:#838383;font-size:15px}.clock-out .submit-btn{position:fixed;bottom:0;background-image:none;background-color:var(--theme-green);border:none;color:#fff;width:100%;padding:20px;text-align:center}
.home{width:100%;display:flex;flex-direction:column;align-items:center}.home .profile-container{display:flex;flex-direction:column;align-items:center;grid-gap:5px;gap:5px;margin-top:15px}.home .profile-container img{width:70px;height:70px;background-color:var(--theme-dark-gray);border-radius:100%;object-fit:cover}.home .profile-container .greeting{font-size:20px;font-weight:700;color:var(--theme-font-value)}.home .profile-container .name{color:var(--theme-font-name);text-transform:capitalize}.home .button-container{width:100%;display:flex;flex-direction:column;grid-gap:10px;gap:10px;margin-top:20px;padding:20px}.home .button-container .btn{border-radius:3px;background-image:none;background-color:var(--theme-green);border:none;color:#fff;width:100%;box-shadow:#666 0px 0px 3px;padding:20px;display:flex;flex-direction:row;align-items:center;justify-content:space-between}.home .button-container .btn .text-section{display:flex;flex-direction:column;grid-gap:5px;gap:5px}.home .button-container .btn .text-section .title{font-size:18px;font-weight:700px}.home .button-container .btn .text-section .desc{font-size:10px;font-weight:300}
.map-page .leaflet-container{width:100%;height:300px}.map-page .form-container{width:100%;padding:20px}.map-page .form-container textarea{width:100%;height:100px;resize:none;padding:10px;border:none;border-radius:3px;background-color:var(--theme-gray);font-family:Poppins}.map-page .form-container textarea::-webkit-input-placeholder{font-family:FontAwesome,Poppins}.map-page .form-container .btn{margin-top:20px;background-image:none;background-color:var(--theme-green);border:none;border-radius:5px;box-shadow:none;color:#fff;width:100%;padding:20px;text-align:center}
.outstation{width:100%;position:relative;display:flex;flex-direction:column;grid-gap:10px;gap:10px;padding:10px 20px}.outstation input{padding:10px;border:none;border-radius:3px;background-color:var(--theme-gray);font-family:Poppins}.outstation select{padding:10px;border:none;border-radius:3px;background-color:var(--theme-gray);font-family:Poppins}.outstation textarea{width:100%;height:100px;resize:none;padding:10px;border:none;border-radius:3px;background-color:var(--theme-gray);font-family:Poppins}.outstation textarea::-webkit-input-placeholder{font-family:Poppins}.outstation .submit-btn{position:fixed;bottom:0;left:0;background-image:none;background-color:var(--theme-green);border:none;color:#fff;width:100%;padding:20px;text-align:center}
.permit{width:100%;position:relative;display:flex;flex-direction:column;grid-gap:10px;gap:10px;padding:10px 20px}.permit .select{padding:10px;border:none;border-radius:3px;background-color:var(--theme-gray);font-family:Poppins;width:100%}.permit .upload-photo input{display:none}.permit .upload-photo .label{font-size:12px;color:var(--theme-font-upload)}.permit .upload-photo .file-box{width:100%;max-width:100%;display:flex;flex-direction:row;justify-content:space-between;align-items:center;border:gray 1px solid;padding:5px}.permit .upload-photo .file-box .text{font-size:12px;margin:0;color:var(--theme-font-upload);text-overflow:ellipsis;overflow:hidden;white-space:nowrap}.permit textarea{width:100%;height:100px;resize:none;padding:10px;border:none;border-radius:3px;background-color:var(--theme-gray);font-family:Poppins}.permit textarea::-webkit-input-placeholder{font-family:Poppins}.permit .submit-btn{position:fixed;bottom:0;left:0;background-image:none;background-color:var(--theme-green);border:none;color:#fff;width:100%;padding:20px;text-align:center}
.header{height:60px;width:100%;position:fixed;background-color:#fff;display:flex;align-items:center;justify-content:center;box-shadow:#666 0px -3px 10px;z-index:999}.header .title{font-size:18px;color:var(---theme-font-header)}.header .back-button{position:absolute;left:20px}.header .logout-button{position:absolute;right:20px}.white-space{height:60px}
