.container {
  padding: 10px 20px;
  .btn-selfie {
    background-image: none;
    background-color: var(--theme-green);
    border: none;
    border-radius: 5px;
    color: white;
    width: 100%;
    padding: 20px;
    text-align: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}

.image-capture {
  width: 100%;
  aspect-ratio: 4/3;
  background-color: #000;
  object-fit: contain;
  object-position: center;
}
