.form-map {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 10px;

  label {
    font-size: 14px;
    color: var(--theme-font-legend);
  }

  textarea {
    resize: none;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: var(--theme-gray);
    font-family: Poppins;
    &::-webkit-input-placeholder {
      font-family: FontAwesome, Poppins;
    }
  }

  .btn-icon {
    border-radius: 4px;
    border: var(--theme-green) solid 1px;
    background-color: white;
    color: var(--theme-font-button);
    text-align: center;
    max-width: max-content;
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 5px;
  }
}