:root {
  --theme-green: #18b8ba;
  --theme-gray: #ebeef5;
  --theme-border: #e8e8e8;
  --theme-dark-gray: rgb(178 188 202);
  --theme-font-upload: #a7a7ad;
  --theme-font-header: #205072;
  --theme-font-legend: #464646;
  --theme-font-value: #222222;
  --theme-font-button: #505050;
  --theme-font-name: #747474;
  --theme-font-button-light: #fff;
  --theme-font-popup-title: #4a4a4a;
  --theme-font-popup-subtitle: #6b6b6e;
}

* {
  font-family: Poppins;
  margin: 0;
  box-sizing: border-box;
}

input, optgroup, select, textarea {
  font-size: 16px;
}

* :focus {
  outline: none !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
